export const INSTANCE_PATH = '/admins';

export const MAX_LOGIN_ATTEMPTS = 3;

export enum RESPONSE_ERROR {
    LOGIN_ATTEMPTS_EXCEEDED = 'LOGIN_ATTEMPTS_EXCEEDED',
}

export enum ACTION_TYPE {
    POST_LOGIN_ADMIN = 'POST_LOGIN_ADMIN',
    POST_LOGIN_ADMIN_SUCCEED = 'POST_LOGIN_ADMIN_SUCCEED',

    GET_AUTH_ADMIN = 'GET_AUTH_ADMIN',
    GET_AUTH_ADMIN_SUCCEED = 'GET_AUTH_ADMIN_SUCCEED',

    POST_LOGOUT_ADMIN = 'POST_LOGOUT_ADMIN',
    POST_LOGOUT_ADMIN_SUCCEED = 'POST_LOGOUT_ADMIN_SUCCEED',
}

export enum PERMISSION_ACTIONS {
    REMOVE_CLIENT = 'REMOVE_CLIENT',
}

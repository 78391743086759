import {IProps} from './types';

import React, {
    FC,
} from 'react';
import ReactCalendar from 'react-calendar';

import DateTime from 'helpers/DateTime';

import 'react-calendar/dist/Calendar.css';

const Calendar: FC<IProps> = (props) => {
    const value = DateTime.checkDateDDMMYYYY(props.value) ?
        DateTime.convertDDMMYYYtoDate(props.value) :
        null;

    const onChange = (date: Date) => {
        const value = DateTime.convertDateToDDMMYYYY(date);

        props.onChange(value);
    };

    return (
        <ReactCalendar
            className={props.className}
            value={value}
            onChange={onChange}
        />
    );
};

export default Calendar;

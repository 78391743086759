class DateTime {
    public static checkTimeHHMM(value: string): boolean {
        if (!/\d\d:\d\d/.test(value)) {
            return false;
        }

        const [hours, minutes] = value
            .split(':')
            .map((item) => parseInt(item));

        if (hours < 0 || hours > 23) {
            return false;
        }

        return !(minutes < 0 || minutes > 59);
    }

    public static convertTimeHHMMToDigits(value: string): [number, number] {
        const [hours, minutes] = value
            .split(':')
            .map((item) => parseInt(item));

        return [hours, minutes];
    }

    public static convertTimeDateToDigits(value: Date): [number, number] {
        return [value.getHours(), value.getMinutes()];
    }

    public static convertDigitsToHHMM(hours: number, minutes: number): string {
        return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
    }

    public static checkDateRFC(value: string): boolean {
        const time = new Date(value).getTime();

        return !isNaN(time);
    }

    public static checkDateDDMMYYYY(value: string): boolean {
        if (!/\d\d.\d\d.\d\d\d\d/.test(value)) {
            return false;
        }

        const [day, month, year] = value.split('.');
        const rfc = `${year}-${month}-${day}`;

        return DateTime.checkDateRFC(rfc);
    }

    public static convertDateDDMMYYYToRFC(value: string): string {
        if (!value) {
            return '';
        }

        const [day, month, year] = value.split('.');

        return `${year}-${month}-${day}`;
    }

    public static convertDateRFCToDDMMYYYY(value: string): string {
        if (!value) {
            return '';
        }

        const [year, month, day] = value.split('-');

        return `${day}.${month}.${year}`;
    }

    public static convertDDMMYYYtoDate(value: string, time = '00:00'): Date {
        const rfc = DateTime.convertDateDDMMYYYToRFC(value);
        const res = new Date(rfc);

        const [hours, minutes] = time
            .split(':')
            .map((item) => parseInt(item));

        res.setHours(hours);
        res.setMinutes(minutes);

        return res;
    }

    public static convertDateToDDMMYYYY(value: Date): string {
        if (isNaN(value.getTime())) {
            return '';
        }

        const day = value.getDate() < 10 ? '0' + value.getDate() : value.getDate();
        const month = value.getMonth() + 1 < 10 ? '0' + (value.getMonth() + 1) : value.getMonth() + 1;
        const year = value.getFullYear();

        return `${day}.${month}.${year}`;
    }

    public static convertDateToRFC(value: Date): string {
        const date = DateTime.convertDateToDDMMYYYY(value);

        return DateTime.convertDateDDMMYYYToRFC(date);
    }

    public static convertRFCToDate(value: string, time = '00:00'): Date {
        const date = new Date(value);
        const [hours, minutes] = time
            .split(':')
            .map((item) => parseInt(item));

        date.setHours(hours);
        date.setMinutes(minutes);

        return date;
    }

    public static getDatesDifference(first: Date, second: Date): number {
        const diffTime = Math.abs(first.getTime() - second.getTime());

        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }
}

export default DateTime;

import {
    IQueryDescription,
    IQueryGenerator,
} from './types';

import * as qs from 'query-string';

class Query {
    public static stringify<IQuery = any>(query?: IQuery) {
        if (!query) {
            return '';
        }

        const result: string[] = [];
        const emptyValues = [undefined, null, ''];

        Object.keys(query).forEach((key) => {
            const item = (query as any)[key];

            if (emptyValues.includes(item)) {
                return;
            }

            switch (typeof item) {
                case 'object': {
                    if (item instanceof Date) {
                        result.push(`${encodeURIComponent(key)}=${encodeURIComponent(item.getTime())}`);
                    } else if (item instanceof Array) {
                        result.push(item.map((elem) => `${key}=${elem}`).join('&'));
                    } else if (item === null) {
                        result.push(`${encodeURIComponent(key)}=${encodeURIComponent(item)}`);
                    } else {
                        throw Error('Unsupported query type');
                    }
                    break;
                }
                default: {
                    result.push(`${encodeURIComponent(key)}=${encodeURIComponent(item)}`);
                    break;
                }
            }
        });

        return `?${result.filter((item) => !!item).join('&')}`;
    }

    public static parse<IQuery>(queryGenerator: IQueryGenerator<IQuery>, queryString: string, description: IQueryDescription<Partial<IQuery>>): IQuery {
        function makeArray<IItem = any>(item: IItem | IItem[]): IItem[] {
            return Array.isArray(item) ? item : [item];
        }

        function collapseArray<IItem = any>(item: IItem | IItem[]): IItem {
            return Array.isArray(item) ? item[0] : item;
        }

        const query = qs.parse(queryString);
        const res = queryGenerator();

        Object.keys(description).forEach((key) => {
            const itemDescription = description[key];
            let itemValue: any = query[key];

            if (itemValue === undefined) {
                return;
            }

            switch (itemDescription.type) {
                case 'string': {
                    res[key] = collapseArray(itemValue);
                    break;
                }
                case 'string[]': {
                    res[key] = makeArray(itemValue);
                    break;
                }
                case 'number': {
                    itemValue = collapseArray(itemValue);

                    const value = parseInt(itemValue);

                    res[key] = isNaN(value) ? undefined : value;
                    break;
                }
                case 'number[]': {
                    itemValue = makeArray(itemValue);

                    res[key] = itemValue
                        .map((item) => parseInt(item))
                        .filter((item) => !isNaN(item));

                    break;
                }
                case 'date': {
                    itemValue = collapseArray(itemValue);

                    const value = new Date(parseInt(itemValue));

                    res[key] = isNaN(value.getTime()) ? undefined : value;
                    break;
                }
                case 'date[]': {
                    itemValue = makeArray(itemValue);

                    res[key] = itemValue
                        .map((item) => parseInt(item))
                        .map((item) => new Date(item))
                        .filter((item) => !isNaN(item.getTime()));
                    break;
                }
                case 'enum': {
                    if (!itemDescription.enum) {
                        throw new Error(`"enum" property is required for "enum" field type ${key}`);
                    }

                    itemValue = collapseArray(itemValue);
                    res[key] = itemDescription.enum.includes(itemValue) ? itemValue : undefined;
                    break;
                }
                case 'enum[]': {
                    if (!itemDescription.enum) {
                        throw new Error(`"enum" property is required for "enum[]" field type ${key}`);
                    }

                    itemValue = makeArray(itemValue);
                    res[key] = itemValue.filter((item) => itemDescription.enum.includes(item));
                    break;
                }
            }
        });

        return res;
    }
}

export default Query;

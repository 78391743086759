import {IProps} from './types';

import classNames from 'classnames';
import React, {FC} from 'react';

import styles from './SmallTitle.module.scss';

const SmallTitle: FC<IProps> = (props) => {
    if (!props.text) {
        return null;
    }

    return (
        <div className={classNames(styles.smallTitle, props.className)}>
            {props.text}
        </div>
    );
};

export default SmallTitle;

import {IPosition} from './types';

export function localToAbsolutePosition(image: HTMLImageElement, position: IPosition): IPosition | null {
    if (!position.width) {
        return null;
    }
    if (!position.height) {
        return null;
    }
    if (position.x === undefined) {
        return null;
    }
    if (position.y === undefined) {
        return null;
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    return {
        width: position.width * scaleX,
        height: position.height * scaleY,
        x: position.x * scaleX,
        y: position.y * scaleY,
    };
}

export function absoluteToLocalPosition(image: HTMLImageElement, position: IPosition): IPosition {
    const scaleX = image.width / image.naturalWidth;
    const scaleY = image.height / image.naturalHeight;

    return {
        width: position.width * scaleX,
        height: position.height * scaleY,
        x: position.x * scaleX,
        y: position.y * scaleY,
    };
}

import {IPostLoginByAdminBody} from './types/requests';
import {
    IGetAuthByAdmin,
    IPostLoginByAdmin,
    IPostLogoutByAdmin,
} from './types/responses';

import config from 'config';

import Fetch from 'tools/Fetch';

import {
    INSTANCE_PATH,
    RESPONSE_ERROR,
} from './constants';

export async function postLoginByAdmin(body: IPostLoginByAdminBody): Promise<IPostLoginByAdmin> {
    return new Fetch<IPostLoginByAdmin>({
        url: `${config.URL}${INSTANCE_PATH}/login`,
        method: 'POST',
        body,
    })
        .on([200], (body) => {
            return body;
        })
        .on([400, 401, 500], (body) => {
            switch (body.error) {
                case RESPONSE_ERROR.LOGIN_ATTEMPTS_EXCEEDED: {
                    return {attemptsExceededError: body.error};
                }
                default: {
                    return body;
                }
            }
        })
        .exec();
}

export async function getAuthByAdmin(): Promise<IGetAuthByAdmin> {
    return new Fetch<IGetAuthByAdmin>({
        url: `${config.URL}${INSTANCE_PATH}`,
        method: 'GET',
    })
        .on([200], (body) => {
            return body;
        })
        .on([401, 500], (body) => {
            return body;
        })
        .exec();
}

export async function postLogoutByAdmin(): Promise<IPostLogoutByAdmin> {
    return new Fetch<IPostLogoutByAdmin>({
        url: `${config.URL}${INSTANCE_PATH}/logout`,
        method: 'POST',
    })
        .onEmptyBody([204])
        .on([401, 500], (body) => {
            return body;
        })
        .exec();
}

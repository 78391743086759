import * as NSRedux from './types/redux';
import * as NSResponses from './types/responses';
import * as NSSaga from './types/saga';

import {
    call,
    put,
    takeLatest,
} from 'redux-saga/effects';

import {ACTION_TYPE} from './constants';
import * as fetches from './fetches';

export function postLoginByAdmin(): NSSaga.IPostLoginByAdmin {
    function* caller(action: NSRedux.IPostLoginByAdmin) {
        const res: NSResponses.IPostLoginByAdmin = yield call(fetches.postLoginByAdmin, action.body);

        if (res.error) {
            console.log(res.error);

            action.resolve(res);

            return;
        }
        if (!res.admin || res.attemptsExceededError || res.failedLoginAttempts !== undefined) {
            action.resolve(res);

            return;
        }

        yield put<NSRedux.IPostLoginByAdminSucceed>({
            type: ACTION_TYPE.POST_LOGIN_ADMIN_SUCCEED,
            admin: res.admin,
        });
        action.resolve(res);
    }

    function* taker() {
        yield takeLatest(ACTION_TYPE.POST_LOGIN_ADMIN, caller);
    }

    return {
        taker,
        caller,
    };
}

export function getAuthByAdmin(): NSSaga.IGetAuthByAdmin {
    function* caller(action: NSRedux.IGetAuthByAdmin) {
        const res: NSResponses.IGetAuthByAdmin = yield call(fetches.getAuthByAdmin);

        if (!res.admin || res.error) {
            console.log(res.error);

            action.resolve(res);

            return;
        }

        yield put<NSRedux.IGetAuthByAdminSucceed>({
            type: ACTION_TYPE.GET_AUTH_ADMIN_SUCCEED,
            admin: res.admin,
        });
        action.resolve(res);
    }

    function* taker() {
        yield takeLatest(ACTION_TYPE.GET_AUTH_ADMIN, caller);
    }

    return {
        taker,
        caller,
    };
}

export function postLogoutByAdmin(): NSSaga.IPostLogoutByAdmin {
    function* caller(action: NSRedux.IPostLogoutByAdmin) {
        const res: NSResponses.IPostLogoutByAdmin = yield call(fetches.postLogoutByAdmin);

        if (res.error) {
            console.log(res.error);

            action.resolve(res);

            return;
        }

        yield put<NSRedux.IPostLogoutByAdminSucceed>({
            type: ACTION_TYPE.POST_LOGOUT_ADMIN_SUCCEED,
        });
        action.resolve(res);
    }

    function* taker() {
        yield takeLatest(ACTION_TYPE.POST_LOGOUT_ADMIN, caller);
    }

    return {
        taker,
        caller,
    };
}

import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import {useAuthByAdmin} from 'instances/admins/hooks';

import {Loading} from 'components/Helpers/Other';

const SessionHandler: FC = (props) => {
    const [isPending, setIsPending] = useState(true);

    const {getAuth} = useAuthByAdmin();

    useEffect(() => {
        (async () => {
            setIsPending(true);
            await getAuth();
            setIsPending(false);
        })();
    }, []);

    if (isPending) {
        return (
            <Loading isPage={true}/>
        );
    }

    return props.children as ReactElement;
};

export default SessionHandler;

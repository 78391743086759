import {createBrowserHistory} from 'history';
import {routerMiddleware} from 'react-router-redux';
import {
    Action,
    applyMiddleware,
    createStore,
    Reducer,
    Store,
} from 'redux';
import createSagaMiddleware from 'redux-saga';

export default function<IReduxState, IReduxAction extends Action>(reducer: Reducer<IReduxState, IReduxAction>, saga: any): Store<IReduxState, IReduxAction> {
    const sagaMiddleware = createSagaMiddleware();
    const history = createBrowserHistory();
    const middleWares = [
        routerMiddleware(history),
        sagaMiddleware,
    ];
    // @ts-ignore
    const store = createStore<IReduxState, IReduxAction, null, null>(reducer, applyMiddleware(...middleWares));

    sagaMiddleware.run(saga);

    return store;
}

import {IProps} from './types';

import React, {
    FC,
    useState,
} from 'react';
import ReactTooltip from 'react-tooltip';

import styles from './Tooltip.module.scss';

const Tooltip: FC<IProps> = (props) => {
    const [id] = useState(() => `${Math.random()}`);

    return (
        <div
            data-tip={true}
            data-for={id}
        >
            {props.children}
            <ReactTooltip
                id={id}
                place={props.position}
                className={styles.tooltip}
            >
                {props.text}
            </ReactTooltip>
        </div>
    );
};

export default Tooltip;

import {IState} from './types/redux';
import {IReduxAction} from 'instances/types';

import {ACTION_TYPE} from './constants';

const initState: IState = {
    adminAuth: null,
};

export default function accounts(state: IState = initState, action: IReduxAction): IState {
    switch (action.type) {
        case ACTION_TYPE.POST_LOGIN_ADMIN: {
            return {
                ...state,
                adminAuth: null,
            };
        }
        case ACTION_TYPE.POST_LOGIN_ADMIN_SUCCEED: {
            return {
                ...state,
                adminAuth: action.admin,
            };
        }
        case ACTION_TYPE.GET_AUTH_ADMIN_SUCCEED: {
            return {
                ...state,
                adminAuth: action.admin,
            };
        }
        case ACTION_TYPE.POST_LOGOUT_ADMIN_SUCCEED: {
            return {
                ...state,
                adminAuth: null,
            };
        }
        default: {
            return state;
        }
    }
}

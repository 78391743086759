import {IProps} from './types';

import classNames from 'classnames';
import React, {FC} from 'react';

import styles from './Card.module.scss';

const Card: FC<IProps> = (props) => {
    return (
        <div className={classNames(styles.card, props.className)}>
            {
                props.title &&
                <div className={styles.title}>
                    {props.title}
                </div>
            }
            {props.children}
        </div>
    );
};

export default Card;

import {IProps} from './types';

import React, {
    FC,
    useEffect,
    useState,
} from 'react';
import ReactCrop, {Crop} from 'react-image-crop';

import 'react-image-crop/dist/ReactCrop.css';

import {
    absoluteToLocalPosition,
    localToAbsolutePosition,
} from './functions';

const CropImage: FC<IProps> = (props) => {
    const [crop, setCrop] = useState<Crop>({
        unit: 'px',
        width: props.width,
        height: props.height,
        aspect: props.width / props.height,
        x: 0,
        y: 0,
    });
    const [imageRef, setImageRef] = useState<HTMLImageElement | null>(null);

    const onChange = (newCrop: Crop) => {
        if (newCrop.width && newCrop.width < props.width) {
            newCrop.width = props.width;

            if (imageRef?.width) {
                newCrop.x = imageRef.width - props.width;
            } else {
                newCrop.x = 0;
            }
        }
        if (newCrop.height && newCrop.height < props.height) {
            newCrop.height = props.height;

            if (imageRef?.height) {
                newCrop.y = imageRef.height - props.height;
            } else {
                newCrop.y = 0;
            }
        }
        if (newCrop.width / newCrop.height !== newCrop.aspect) {
            if (newCrop.width === crop.width) {
                newCrop.width = (crop.aspect ?? 1) * crop.height;
            }
            if (newCrop.height !== crop.height) {
                newCrop.height = (crop.aspect ?? 1) / crop.width;
            }
        }

        setCrop(newCrop);
    };
    const onCrop = (crop: Crop) => {
        setCrop(crop);

        if (!imageRef) {
            return;
        }

        const position = localToAbsolutePosition(imageRef, crop);

        if (!position) {
            return;
        }

        props.onChange({
            width: Math.round(position.width),
            height: Math.round(position.height),
            x: Math.round(position.x),
            y: Math.round(position.y),
        });
    };

    useEffect(() => {
        if (imageRef) {
            const localPosition = props.initialState ? absoluteToLocalPosition(imageRef, props.initialState) : null;

            const newCrop: Crop = localPosition ? {
                ...crop,
                ...localPosition,
            } : crop;

            localPosition && setCrop(newCrop);

            const imageData = localToAbsolutePosition(imageRef, newCrop);

            if (!imageData) {
                return;
            }

            props.onChange({
                width: Math.round(imageData.width),
                height: Math.round(imageData.height),
                x: Math.round(imageData.x),
                y: Math.round(imageData.y),
            });
        }
    }, [imageRef]);

    return (
        <ReactCrop
            src={props.image}
            crop={crop}
            ruleOfThirds={true}
            onImageLoaded={setImageRef}
            onDragStart={props.onDragStart}
            onDragEnd={props.onDragEnd}
            onChange={onChange}
            onComplete={onCrop}
            minWidth={props.width}
            minHeight={props.height}
            keepSelection={true}
            circularCrop={props.isCircle}
        />
    );
};

export default CropImage;

import {IProps} from './types';

import React, {FC} from 'react';
import {Route} from 'react-router-dom';

import {onLogoutAdmin} from 'instances/admins/functions';
import {useAuthByAdmin} from 'instances/admins/hooks';

import Loading from '../Loading';

const PermissibleRoute: FC<IProps> = (props) => {
    const {adminAuth} = useAuthByAdmin();

    if (props.isAuth) {
        if (!adminAuth) {
            onLogoutAdmin();

            return <Loading isPage={true}/>;
        }
    }

    return (
        <Route {...props}/>
    );
};

export default PermissibleRoute;

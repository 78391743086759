import React, {FC} from 'react';
import {useHistory} from 'react-router-dom';

import styles from './Navigation.module.scss';

const Navigation: FC = () => {
    const history = useHistory();

    return (
        <div className={styles.navigation}>
            <div className={styles.row}>
                <div
                    className={history.location.pathname === '/bids' ? styles.activeLink : styles.link}
                    onClick={() => history.push('/orders')}
                >
                    Заказы
                </div>
                <div
                    className={history.location.pathname === '/clients' ? styles.activeLink : styles.link}
                    onClick={() => history.push('/clients')}
                >
                    Клиенты
                </div>
                <div
                    className={history.location.pathname === '/products' ? styles.activeLink : styles.link}
                    onClick={() => history.push('/products')}
                >
                    Оборудование
                </div>
                <div
                    className={history.location.pathname === '/categories' ? styles.activeLink : styles.link}
                    onClick={() => history.push('/categories')}
                >
                    Категории
                </div>
            </div>
            <div className={styles.row}>
                <div
                    className={history.location.pathname === '/issue_points' ? styles.activeLink : styles.link}
                    onClick={() => history.push('/issue_points')}
                >
                    Склады
                </div>
                <div
                    className={history.location.pathname === '/deliveries' ? styles.activeLink : styles.link}
                    onClick={() => history.push('/deliveries')}
                >
                    Доставки
                </div>
                <div
                    className={history.location.pathname === '/areas' ? styles.activeLink : styles.link}
                    onClick={() => history.push('/areas')}
                >
                    Области
                </div>
            </div>
        </div>
    );
};

export default Navigation;

import {IProps} from './types';

import React, {FC} from 'react';

import arrowImage from './media/arrow.svg';

import styles from './Crumbs.module.scss';

const Crumbs: FC<IProps> = (props) => {
    return (
        <div className={styles.crumbs}>
            {
                props.locations.map(
                    (location, i) =>
                        <div
                            key={i}
                            className={styles.location}
                        >
                            {
                                i !== 0 &&
                                <div className={styles.arrow}>
                                    <img
                                        src={arrowImage}
                                        alt={''}
                                    />
                                </div>
                            }
                            <div
                                className={styles.name}
                                onClick={location.onClick}
                            >
                                {location.name}
                            </div>
                        </div>
                )
            }
        </div>
    );
};

export default Crumbs;

export interface IConfig {
    MODE: 'DEV' | 'TEST' | 'PROD';
    URL: string;
    WS_URL: string;

    YANDEX_MAPS: string;
    YANDEX_GEO: string;

    SITE_URL: string;
}

const config: IConfig = {
    MODE: String(process.env.REACT_APP_MODE) as 'DEV' | 'TEST' | 'PROD',
    URL: String(process.env.REACT_APP_URL),
    WS_URL: String(process.env.REACT_APP_WS_URL),

    YANDEX_MAPS: String(process.env.REACT_APP_YANDEX_MAPS),
    YANDEX_GEO: String(process.env.REACT_APP_YANDEX_GEO),

    SITE_URL: String(process.env.REACT_APP_SITE_URL),
};

export default config;

import {IProps} from './types';

import classNames from 'classnames';
import React, {FC} from 'react';

import styles from './Container.module.scss';

const Container: FC<IProps> = (props) => {
    return (
        <div className={classNames(props.className, styles.container)}>
            <div className={styles.content}>
                {props.children}
            </div>
        </div>
    );
};

export default Container;

import {IProps} from './types';

import React, {FC} from 'react';

const MultilineText: FC<IProps> = (props) => {
    return (
        <>
            {
                props.text
                    .split('\n')
                    .map((item, i) =>
                        <div key={i}>
                            {item}
                            <br/>
                        </div>
                    )
            }
        </>
    );
};

export default MultilineText;

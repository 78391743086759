import {IPostLoginByAdminBody} from './types/requests';

export function onLogoutAdmin() {
    window.location.replace('/login');
}

export function getDefaultPostLoginBody(): IPostLoginByAdminBody {
    return {
        login: '',
        password: '',
    };
}

import React, {
    FC,
    lazy,
    Suspense,
    useEffect,
} from 'react';
import {Provider} from 'react-redux';
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from 'react-router-dom';

import {createReduxStore} from 'tools/redux';

import reducer from 'instances/reducer';
import saga from 'instances/saga';

import {SessionHandler} from 'components/Helpers/Background';
import {
    Loading,
    PermissibleRoute,
} from 'components/Helpers/Other';

const Login = lazy(() => import('./components/Login'));
const Client = lazy(() => import('./components/Client'));
const Clients = lazy(() => import('./components/Clients'));
const Order = lazy(() => import('./components/Order'));
const OrderView = lazy(() => import('./components/OrderView'));
const Orders = lazy(() => import('./components/Orders'));
const Products = lazy(() => import('./components/Products'));
const Categories = lazy(() => import('./components/Categories'));
const IssuePoints = lazy(() => import('./components/IssuePoints'));
const Deliveries = lazy(() => import('./components/Deliveries'));
const Areas = lazy(() => import('./components/Areas'));
const NotFound = lazy(() => import('./components/NotFound'));

const store = createReduxStore(reducer, saga);

const App: FC = () => {
    useEffect(() => {
        if (window.location.host === 'crm.dandypride.ru') {
            window.location.replace(`https://www.crm.dandypride.ru${window.location.pathname}`);
        }
    }, []);

    return (
        <Provider store={store}>
            <Router>
                <SessionHandler>
                    <Suspense
                        fallback={
                            <div className={'d-flex flex-row justify-content-center'}>
                                <Loading isPage={true}/>
                            </div>
                        }
                    >
                        <Switch>
                            <Route exact={true} path={'/'} component={() => <Redirect to={'/clients'}/>}/>
                            <Route exact={true} path={'/login'} component={Login}/>
                            <Route exact={true} path={'/o/:encodedDisplayId'} component={OrderView}/>
                            <PermissibleRoute exact={true} path={'/clients'} component={Clients} isAuth={true}/>
                            <PermissibleRoute exact={true} path={'/clients/:identifier'} component={Client} isAuth={true}/>
                            <PermissibleRoute exact={true} path={'/orders'} component={Orders} isAuth={true}/>
                            <PermissibleRoute exact={true} path={'/orders/:action'} component={Order} isAuth={true}/>
                            <PermissibleRoute exact={true} path={'/orders/:action/:id'} component={Order} isAuth={true}/>
                            <PermissibleRoute exact={true} path={'/products'} component={Products} isAuth={true}/>
                            <PermissibleRoute exact={true} path={'/categories'} component={Categories} isAuth={true}/>
                            <PermissibleRoute exact={true} path={'/issue_points'} component={IssuePoints} isAuth={true}/>
                            <PermissibleRoute exact={true} path={'/deliveries'} component={Deliveries} isAuth={true}/>
                            <PermissibleRoute exact={true} path={'/areas'} component={Areas} isAuth={true}/>
                            <PermissibleRoute exact={true} path={'*'} component={NotFound}/>
                        </Switch>
                    </Suspense>
                </SessionHandler>
            </Router>
        </Provider>
    );
};

export default App;

import {IProps} from './types';

import React, {
    FC,
    useEffect,
} from 'react';
import ReactModal from 'react-modal';

import styles from './Modal.module.scss';

const Modal: FC<IProps> = (props) => {
    const onRequestClose = () => {
        props.onClose();
    };

    useEffect(() => {
        if (props.isOpen) {
            document.body.style.overflowY = 'hidden';
        }

        return () => {
            document.body.style.overflowY = 'initial';
        };
    }, [props.isOpen]);

    return (
        <ReactModal
            isOpen={props.isOpen}
            onRequestClose={onRequestClose}
            className={styles.modal}
            overlayClassName={styles.overlay}
            ariaHideApp={false}
        >
            {
                props.isOpen &&
                props.children
            }
        </ReactModal>
    );
};

export default Modal;

import {IRootReduxState} from '../types';
import * as NSRedux from './types/redux';
import {IPostLoginByAdminBody} from './types/requests';
import {
    IGetAuthByAdmin,
    IPostLoginByAdmin,
    IPostLogoutByAdmin,
} from './types/responses';

import {
    useDispatch,
    useSelector,
} from 'react-redux';

import {ACTION_TYPE} from './constants';

export function useAuthByAdmin() {
    const {adminAuth} = useSelector<IRootReduxState, NSRedux.IState>((state) => state.admins);
    const dispatch = useDispatch();

    const postLogin = async (body: IPostLoginByAdminBody): Promise<IPostLoginByAdmin> => {
        return new Promise<IPostLoginByAdmin>((resolve) => {
            dispatch<NSRedux.IPostLoginByAdmin>({
                type: ACTION_TYPE.POST_LOGIN_ADMIN,
                body,
                resolve,
            });
        });
    };
    const getAuth = async (): Promise<IGetAuthByAdmin> => {
        return new Promise<IGetAuthByAdmin>((resolve) => {
            dispatch<NSRedux.IGetAuthByAdmin>({
                type: ACTION_TYPE.GET_AUTH_ADMIN,
                resolve,
            });
        });
    };
    const postLogout = async () => {
        return new Promise<IPostLogoutByAdmin>((resolve) => {
            dispatch<NSRedux.IPostLogoutByAdmin>({
                type: ACTION_TYPE.POST_LOGOUT_ADMIN,
                resolve,
            });
        });
    };

    return {
        adminAuth,
        postLogin,
        getAuth,
        postLogout,
    };
}

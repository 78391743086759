import {
    all,
    ForkEffect,
} from 'redux-saga/effects';

import * as admins from './admins/saga';

const sagas: IterableIterator<ForkEffect>[] = [];
const instances = [
    admins,
];

for (const saga of Object.values(instances)) {
    for (const method of Object.values(saga)) {
        sagas.push(
            method()
                .taker()
        );
    }
}

export default function* saga() {
    yield all(sagas);
}

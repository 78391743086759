import {IRootReduxState} from './types';

import {routerReducer} from 'react-router-redux';
import {combineReducers} from 'redux';

import admins from './admins/reducer';

export default combineReducers<IRootReduxState>({
    routing: routerReducer,
    admins,
});
